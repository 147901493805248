
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import URL from "../../auth/Globals";

export default function SendNotification(props) {

    const location = useLocation();
    const navigate = useNavigate();

    const path = "/notification"
    const ENDPOINT = URL + path;
    const [notification, setNotification] = useState({
        title: "",
        body: "",
        image: "",
        topics: "",
        type: ""
    });

    const [show, setShow] = useState(false);

    const handleChange = (event) => {
        setNotification(prevNot => ({
            ...prevNot,
            [event.target.name]: event.target.value

        }));
    }

    const handleChangeArr = (event) => {

        setNotification(prevNot => ({
            ...prevNot,
            [event.target.name]: [event.target.value]


        }));
    }



    const onSearchPressed = (event) => {
        event.preventDefault();

        if (notification.topic == "") {
            window.alert("Select valid notification type");
        } else {
            postQuery();
        }
    }




    const postQuery = async (obj) => {

        //testNot = "title:"

        console.log("Sending notificaiton to: " + JSON.stringify(notification) + ENDPOINT);
        console.log(props.token)
        fetch((ENDPOINT),
            {
                mode: 'cors',
                //credentials: 'include',
                method: 'post',
                body: JSON.stringify(notification),
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': props.token
                }
            }
        )
        .then(response =>  response.json().then(data => ({status: response.status, body: data})))
        .then(data => {

            if (data == undefined) {
                window.alert("Something went wrong");
                return;
            }

            if(data.status==403){

                alert("Session expired, please log in again")
                props.setLogedIn(false)
                const { from } = location.state || { from: { pathname: "/login" } };  
                navigate(from, {replace:true});   
                
            }


            });
    }


    return (
        <>


            <div className='center'>
                <form method="post" onSubmit={onSearchPressed}>
                    <div>
                        <label style={{ textAlign: 'center' }}>Input information to send notification to specific users.</label>
                    </div>
                    <div>
                        <select id="field" name="topics" required onChange={handleChangeArr.bind(this)}>
                            <option value="default" hidden>
                                -/-
                            </option>
                            <option value="all">All</option>
                            <option value="android">Android</option>
                            <option value="ios">iOS</option>
                            <option value="member">Members</option>
                            <option value="superMember">Super Members</option>
                            <option value="arthra_apopsis">Articles(checkcode..)</option>
                        </select>
                        <br />
                        <label style={{ textAlign: 'center' }}>Title</label>
                        <input id="title" type="text" name="title" autoComplete='off'
                            placeholder="Title" required onChange={handleChange} />
                        <br />
                        <label style={{ textAlign: 'center' }}>Content</label>
                        <input id="body" type="text" name="body" autoComplete='off'
                            placeholder="Content" required onChange={handleChange} />
                        <br />
                        <label style={{ textAlign: 'center' }}>Image Link(Optional)</label>
                        <input id="image" type="text" name="image" autoComplete='off'
                            placeholder="Image URL" onChange={handleChange} />
                    </div>
                    <div>
                        <input type='submit' value='Send Notification' />
                    </div>
                </form>


            </div>

            {true && <div className="container">


            </div>}
        </>



    )
}