import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Logout = (props) => {

  const location = useLocation();
  const navigate = useNavigate();




  const logout = async () => {

      props.setLogedIn(false)


  }
  useEffect(() => {
    logout();
  }, [])

  if (props.loggedIn !== null) {
    const { from } = location.state || { from: { pathname: "/home" } };
    navigate(from, { replace: true });
  }

  return <div></div>;
};

export default Logout;
