//import { BrowserRouter, Routes, Route } from "react-router-dom";
import './Home.css'

import React, { Component }  from 'react';

const Home = () => {

    return (
        <>
            <h1>Home</h1>

            <h2>GENERAL INFORMATION</h2>
            <div>
                Καλωσήλθατε στη σελίδα διαχείρησης της εφαρμογής 'ΠΑΣΟΚ-ΚΙΝΑΛ'.

                <p>Για οποιαδήποτε λειτουργία θα χρειαστεί να εισάγετε το όνομα και τον κωδικό χρήστη διαχειρηστη. </p>

                <h3>USERS</h3>
                <p>Εδω θα μπορείτε να δείτε και να ψάξετε όλους τους χρήστες που είναι εγγεγραμένοι στην εφαρμογή.
                </p>
                <h3>NOTIFICATIONS</h3>
                <p>Εδώ μπορείτε να στείλετε μία νέα ειδοποίηση στους χρήστες της εφαρμογής, παρέχοντας τις κατάλληλες πληροφορίες.
                </p>
                <h3>LINKS</h3>
                <p>
                    Εδω μπορείτε να αλλάξετε τα λινκς τα οποία παρέχουν πληροφορίες στην εφαρμογή.
                </p>
            </div>
        </>

    )
};

export default Home;