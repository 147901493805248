import React from 'react';
import base64 from 'react-native-base64';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import '../../App.css'
import URL from '../../auth/Globals';

const Login = (props) => {

    const ENDPOINT = URL + "/dashboard-login"

    const [credentials, setCredentials] = useState({ username: '', password: '' })
    const location = useLocation();
    const navigate = useNavigate();

    //console.log(props.user)



    const [loginMessage, setLoginMessage] = useState("");

    const onLoginPressed = (event) => {
        event.preventDefault()

        //login(credentials.username, credentials.password);
        console.log(credentials.username)
        sendCredential();

    };

    const handleInputChange = (event) => {
        setCredentials(prevCreds => ({

            ...prevCreds,
            [event.target.name]: event.target.value

        }));

    }



    // const sendCredentials = async (username, password) => {
    //     console.log("about to login...")
    //     const res = fetch(URL + '/dashboard-login',
    //         {
    //             mode: 'cors',
    //             credentials: 'include',
    //             headers: new Headers({
    //                 "Authorization": `Basic ${base64.encode(`${username}:${password}`)}`,
    //                 "X-Requested-With": "XMLHttpRequest"
    //             })
    //         }

    //     )
    //         .then(response => {
    //             console.log(response)
    //             if (response.status === 401) {
    //                 setLoginMessage("Wrong Credentials. Please try again")
    //                 return;
    //             } else if (response.status === 200) {
    //                 return response.json()
    //             }
    //         }).then(data => {
    //             console.log(data)
    //             if (data === undefined) return;
    //             let responseRole = ''
    //             data.authorities.forEach(function (item, index) {
    //                 console.log(item.role)
    //                 if (item.role.startsWith('ROLE')) {
    //                     console.log('role is ' + item.role)
    //                     responseRole = item.role
    //                 }
    //             });
    //             if (responseRole === 'ROLE_ANONYMOUS' || responseRole === 'ROLE_ADMIN') {
    //                 setLoginMessage("Wrong Credentials. Please try again")
    //                 props.setUser({ role: 'ROLE_ANONYMOUS', loggedIn: false })
    //                 console.log('logged out')
    //             } else {
    //                 props.setUser({ role: responseRole, loggedIn: true })
    //                 const { from } = location.state || { from: { pathname: "/profile" } };
    //                 navigate(from, { replace: true });
    //             }
    //         })

    // }


    const sendCredential = async () => {
        
        fetch((ENDPOINT),
        {
            mode: 'cors',
            method: 'post',
            body: JSON.stringify(credentials),
            headers: {
                'Content-Type': 'application/json'
            }
        })        
        .then(response =>  response.json().then(data => ({status: response.status, body: data})))
        .then(response => {
        if (response.status === 400) {
            setLoginMessage("Wrong Credentials. Please try again")
            return;
        } else if (response.status === 200) {
            props.setLogedIn(true)
            props.setToken(response.body.token)

            const { from } = location.state || { from: { pathname: "/home" } };  
            navigate(from, {replace:true});   
            //return response.json()
        }else{
            setLoginMessage("Something went wrong")
            return;
        }

        })
        
        .catch(error => {
            //console.log(error)
            setLoginMessage("Serverside error")
        });

    }

    return (
        <>
            {<div>


                <h2 style={{ textAlign: "center" }}>Sign In</h2>
                <h3 style={{ textAlign: "center" }}>{loginMessage}</h3>
                <div className='center'>


                    <form method="post" onSubmit={onLoginPressed}>
                        <div>

                            <input id="username" type="text" name="username" autoComplete='off'
                                placeholder="Your Username" onChange={handleInputChange.bind(this)} />
                        </div>

                        <div>

                            <input id="password" type="password" name="password" autoComplete='off'
                                placeholder="Your Password" onChange={handleInputChange.bind(this)} />
                        </div>
                        <div>
                            <input type="submit" value="Login" />
                        </div>

                    </form>

                </div>
            </div>}

        </>

    );
};

export default Login;
