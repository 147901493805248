import React from 'react';
import { useEffect } from 'react';
import { Outlet, Link } from "react-router-dom";
import './Navigation.css';


const Navigation = (props) => {

  useEffect(() => {
    /*
    props.checkLoggedIn();
    */
  }, []);



  return (
    <div>
      <nav>
        <ul className="bar">
          <li><Link className="node" to="/home">Home</Link></li>
          <li class="node drop">
            <div class="dropbtn">Users
            </div>
            <div class="dropdown-content">
              <li><Link className='node' to='/users-display'>User Display</Link></li>

            </div>
          </li>
          <li><Link className='node' to='/send-notification'>Notifications</Link></li>

          {!props.logedIn ? <li className='right'><Link className='node' to='/login'>Login</Link></li> :
          <li className='right'><Link className='node' to='/logout'>Logout</Link></li>}
          {/* {props.user.loggedIn ? <li className='right'><Link className='node' to='/logout'>Logout</Link></li> :
            <li className='right'><Link className='node' to='/login'>Login</Link></li>} */}


          {/* {role === 'ROLE_CITIZEN' && <li><Link className='node' to='/add-pet'>Add Pet</Link></li>} */}


        </ul>
      </nav>
      <Outlet />
    </div>
  );
};

export default Navigation;
