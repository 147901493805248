import './UserDisplay.css'
import React, { useState, useEffect } from "react";
import URL from '../../auth/Globals';

import { useLocation, useNavigate } from 'react-router-dom';

export default function UsersDisplay(props) {

    const location = useLocation();
    const navigate = useNavigate();

    const [field, setField] = useState('')
    const [value, setValue] = useState('')
    const [users, setUsers] = useState([]);
    const [count, setCount] = useState(0);

    const [show, setShow] = useState(false);

    const handleValueChange = (event) => {
        setValue(event.target.value);
    }

    const handleFieldChange = (event) => {
        setField(event.target.value);
    }

    const ENDPOINT = URL + "/user-management"


    const onSearchPressed = (event) => {
        event.preventDefault();


        if (value != "") {
            let nameObj = { field, value }
            console.log(nameObj);
            postQuery(nameObj);
        } else {
            getAllUsers();
        }

    }

    const getAllUsers = async () => {

        console.log(props.token)
        fetch(ENDPOINT + "/fetch-all",
            {
                mode: 'cors',
                //credentials: 'include',
                method: 'get',
                headers: {"x-access-token": props.token}
            }
        )

        .then(response =>  response.json().then(data => ({status: response.status, body: data})))
        .then(data => {

            if(data.status!=403){
                if (data != undefined) {
                    console.log(data.body)
                    if (data.body.results.length == 0) {
                        //setShow(true);
                        window.alert("No users found in db");

                    }
                    else {
                        setCount(data.body.results.length);
                        setUsers(data.body.results);
                    }
                }}else{
                    alert("Session expired, please log in again")
                    console.log(data.body)
                    props.setLogedIn(false)
                    const { from } = location.state || { from: { pathname: "/login" } };  
                    navigate(from, {replace:true});  
                }

            });
    }




    const postQuery = async (obj) => {
        console.log(JSON.stringify(obj))


        //console.log(props.token)
        fetch(ENDPOINT + "/fetch-by",
            {
                mode: 'cors',
                //credentials: 'include',
                method: 'post',
                body: JSON.stringify(obj),
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': props.token
                }
            }
        )


                .then(response =>  response.json().then(data => ({status: response.status, body: data})))
                .then(data => {
                
                    if(data.body.status!=403){                
                    if (data != undefined) {
                    if (data.body.results.length == 0) {
                        setShow(true);
                        window.alert("No users found");
                        setCount(0);
                        setUsers([]);
                    }
                    else {
                        console.log(data.body.results[0].displayName);
                        setCount(data.body.results.length);
                        setUsers(data.body.results);
                    }
                }}else{
                    alert("Session expired, please log in again")
                    console.log(data.body)
                    props.setLogedIn(false)
                    const { from } = location.state || { from: { pathname: "/login" } };  
                    navigate(from, {replace:true});  
                }

            })
    }


    const type = (num) => {
        if (num === 0) {
            return 'Unverified';
        } else if (num === 1) {
            return 'Member'
        } else {
            return 'Super Member'
        }
    }

    const address = (street, num) => {
        if (street == null) {
            return null
        } else if (num == null) {
            return street;
        } else {
            return street + " - " + num.toString();
        }
    };
    /*
        <Alert variant="danger" show={show} >
        <Alert.Heading>Pets not found</Alert.Heading>
        <p> Nothing found in database with information provided</p>
        <Button onClick={() => setShow(false)}>Close</Button>
    </Alert>
    */
    return (
        <>



            <div className='center'>
                <form method="post" onSubmit={onSearchPressed}>
                    <div>
                        <label style={{ textAlign: 'center' }}>Select field name and input value bleow to search by.(Leave Blank to return all)</label>
                    </div>
                    <div>
                        <select id="field" onChange={handleFieldChange.bind(this)}>
                            <option value="_id">ID</option>
                            <option value="displayName">Fullname</option>
                            <option value="email">Email</option>
                            <option value="phoneNumber">Phone Number</option>
                            <option value="userType">User Type (1 or 2)</option>
                            <option value="voteId">Voting ID</option>
                            <option value="postalCode:">Postal Code</option>
                            <option value="townName:">Town Name</option>
                            <option value="localOrganization:">Local Organization</option>
                            <option value="politicalInterest:">Political Interest</option>
                        </select>
                        <input id="value" type="text" name="value" autoComplete='off'
                            placeholder="Value" onChange={handleValueChange.bind(this)} />
                    </div>
                    <div>
                        <input type='submit' value='Search' />
                    </div>
                </form>


            </div>

            {true && <div className="container">
                <h1>Results</h1>
                <table>
                    <thead>
                        <tr>
                            <th>User ID</th>
                            <th>Fullname</th>
                            <th>Email</th>
                            <th>Phone Num.</th>
                            <th>Home Phone Num.</th>
                            <th>User Type</th>
                            <th>Father's Name</th>
                            <th>Mother's Name</th>
                            <th>Birth Date</th>
                            <th>VotingID</th>
                            <th>Home Address</th>
                            <th>Town</th>
                            <th>Postal Code</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            users.map((entry) => (
                                <tr key={(entry._id).toString()}>
                                    <td>{entry._id}</td>
                                    <td>{entry.displayName}</td>
                                    <td>{entry.email}</td>
                                    <td>{entry.phoneNumber}</td>
                                    <td>{entry.homePhoneNumber}</td>
                                    <td>{type(entry.userType)}</td>
                                    <td>{entry.fathersName}</td>
                                    <td>{entry.mothersName}</td>
                                    <td>{entry.birthDate}</td>
                                    <td>{entry.voteID}</td>
                                    <td>{address(entry.homeAddress, entry.homeStreetNumber)}</td>
                                    <td>{entry.townName}</td>



                                </tr>
                            )
                            )
                        }
                    </tbody>
                </table>
                <p>user total: {count}</p>

            </div>}
        </>



    )



}