
import './App.css';
import React,{ useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, Link, Navigate, useLocation, Outlet } from "react-router-dom";
import Navigation from './Navigation';
import Home from './pages/Home';
import Login from './pages/auth/Login';
import Logout from './pages/auth/Logout';
import { __SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED } from 'react/cjs/react.production.min';
import UsersDisplay from './pages/users/UsersDisplay';
import SendNotification from './pages/notifications/SendNotification';

function App() {

  const [logedIn, setLogedIn] = useState( false )
  const [token, setToken] = useState("")

  // const checkLoggedIn = async () => {
  //   fetch('http://localhost:8080/logged_in',
  //     {
  //       mode: 'cors',
  //       credentials: 'include',
  //       headers: new Headers({

  //         "X-Requested-With": "XMLHttpRequest"
  //       })

  //     }
  //   )
  //     .then(response => response.json())
  //     .then(data => {
  //       let responseRole = ''
  //       data.authorities.forEach(function (item, index) {
  //         console.log(item.role)
  //         if (item.role.startsWith('ROLE')) {
  //           console.log('role is ' + item.role)
  //           responseRole = item.role
  //         }
  //       });

  //       if (responseRole === 'ROLE_ANONYMOUS' || responseRole === 'ROLE_ADMIN') {
  //         setUser({ role: 'ROLE_ANONYMOUS', loggedIn: false })
  //         console.log('logged out')
  //       } else {
  //         console.log('logged in')

  //         setUser({ role: responseRole, loggedIn: true })
  //       }

  //     })

  // };

  // useEffect(() => {
    
  //   checkLoggedIn();
    
  // }, []);



  // const PrivateRoute = (props) => {

  //   const location = useLocation();

  //   return user.loggedIn
  //     ? <props.component user={props.user} />
  //     : <Navigate to="/login" replace state={{ from: location }} />;


  // }

  // const AuthRoute = (props) => {

  //   return !user.loggedIn
  //     ? <Authentication {...props} />
  //     : <Home />
  // }

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigation logedIn={logedIn} /*checkLoggedIn={checkLoggedIn}*/ />}> {/* //TODO PARAMS TO ROUTES */}
            <Route path="/home" element={<Home />} />
            <Route path="/login" element={<Login setLogedIn={setLogedIn} setToken={setToken}/>} />
            <Route path="/logout" element={<Logout setLogedIn={setLogedIn} setToken={setToken}/>} />
            {/* <Route path="/logout" element={<Logout user={user} setUser={setUser} />} /> */}

            {/*Users routes*/}
            <Route path="/users-display" element={logedIn ? <UsersDisplay setLogedIn={setLogedIn} token={token} /> : <Login setLogedIn={setLogedIn} setToken={setToken}/>} />
            <Route path="/send-notification" element={logedIn ? <SendNotification setLogedIn={setLogedIn} token={token}/> : <Login setLogedIn={setLogedIn} setToken={setToken}/>} />




            {/* LOGGEDIN ROUTES */}
            {/*
            <Route path='/add-pet' element={<PrivateRoute component={AddPet} />} />
            <Route path='/view-pets' element={<PrivateRoute component={ViewPets} />} />
            */}


          </Route>
        </Routes>
      </BrowserRouter>
    </>


  );
}

export default App;
